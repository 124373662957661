import { retrieveLoginData } from "../api/auth";

const { createSlice } = require("@reduxjs/toolkit");

const savedData = retrieveLoginData();
const initialState = {
  user: savedData.user,
  token: savedData.token,
  isAuthenticated: !!savedData.token,
  authLevel: savedData.authLevel,
  isSuperAdmin: savedData.isSuperAdmin === "true",
  header: { headers: { Authorization: `Bearer ${savedData.token}` } }
};

// AUTH LEVELS
// National --> 4
// Regional --> 3
// District --> 2
// Circuit --> 1

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login(state, action) {
      const { user, token, authLevel } = action.payload;
      state.token = token;
      state.user = user;
      // console.log(user);
      state.isAuthenticated = true;
      state.header = { headers: { Authorization: `Bearer ${state.token}` } };
      state.authLevel = authLevel;
      state.isSuperAdmin = authLevel % 1 === 0.5;
    },
    logout() {
      // state.token = null;
      // state.isAuthenticated = false;
      // state.user = null;
      window.location.reload();
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
