import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Districts",
  postUrl: null,
  fetchedDistricts: false,
  isFetching: false,
  districts: [],
  teachers: [],
  teacherStats: null,
  districtsCache: {},
  selectedRegion: 0,
  previewDistrict: null,
  districtSummary: null,
  enrolmentData: null,
  attendanceData: null,
  totalEnrolment: 0,
  analyticsQuery: "",
  teacherAttendance: null,
  selectTermFormValues: {
    year: "0",
    term: "0",
    semester: "0"
  },
  textbookData: null,
  studentPerformance: null,
  recordBooksData: null,
  supportAndGrantRecords: null,
  sanitationData: null,
  securityData: null,
  sportsData: null,
  structureData: null,
  furnitureData: null,
  washData: null,
  communityData: null,
  meetingsData: null,
  issuesData: null,
  selectedDistrict: 0,
  validDistricts: [],
  districtToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    name: "",
    description: "",
    region: "0",
    region_id: 0
  }
};

const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
      state.title = "All Districts";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add A District";
      state.postUrl = `${API_URL}/district`;
      state.defaultValues = {
        name: "",
        description: "",
        moeCode: "",
        region: "0",
        region_id: 0
      };
    },
    edit(state, action) {
      const district = action.payload;

      state.mode = "edit";
      state.title = `Edit ${district.name}`;
      state.postUrl = `${API_URL}/district/${district.id}`;

      state.defaultValues = {
        name: district.name,
        description: district.description,
        moeCode: district.moe_integration_system_id || "",
        region_id: district.region_id
      };
    },
    detail(state, action) {
      state.previewDistrict = action.payload;
    },
    delete(state, action) {
      state.mode = "delete";
      state.districtToDelete = {
        text: `Are you sure you want to delete the ${action.payload.name}?`,
        id: action.payload.id
      };
    },
    storeDistricts(state, action) {
      const { districts, user, authLevel } = action.payload;

      if (authLevel > 3.5) state.districts = districts;

      districts.forEach((district) => {
        // Filter for regional admin
        if (authLevel > 2.5 && authLevel < 4) {
          if (district.region_id === user.region.id) {
            state.districts.push(district);
          }

          // Filter for district admin
        } else if (authLevel > 1.5 && authLevel < 3) {
          if (district.id === user.district.id) state.districts = [district];

          // Filter for CS
        } else if (authLevel > 0.5 && authLevel < 2) {
          if (district.id === user.circuit?.district_id) {
            state.districts = [district];
          }
        }

        // Filter for district admin

        if (state.districtsCache[district.id]) return;

        state.districtsCache[district.id] = district.name;
      });
      state.isFetching = false;
    },
    storeTeachers(state, action) {
      state.teachers = action.payload;
    },
    saveTeacherStats(state, action) {
      state.teacherStats = action.payload;
    },
    setFetchedDistricts(state, action) {
      state.fetchedDistricts = action.payload;
    },
    setValidDistricts(state, action) {
      const { districts, regionId } = action.payload;

      if (regionId === state.selectedRegion) return;

      // Return all districts in the selected region
      state.validDistricts = districts.filter((district) => district.region_id === regionId);
      state.selectedRegion = regionId;

      // Set the selected District to "Select A District"
      state.selectedDistrict = 0;
    },
    saveDistrictSummaries(state, action) {
      state.districtSummary = action.payload;
    },
    saveEnrolmentData(state, action) {
      state.enrolmentData = action.payload;
    },
    saveAttendanceData(state, action) {
      state.attendanceData = action.payload;
    },
    saveTeacherAttendance(state, action) {
      state.teacherAttendance = action.payload;
    },
    setAnalyticsQuery(state, action) {
      state.analyticsQuery = action.payload;
    },
    saveTotalEnrolment(state, action) {
      state.totalEnrolment = action.payload;
    },
    storeSelectTermFormValues(state, action) {
      state.selectTermFormValues = action.payload;
    },
    storeTextbookData(state, action) {
      state.textbookData = action.payload;
    },

    storeStudentPerfData(state, action) {
      state.studentPerformance = action.payload;
    },

    storeRecordBooksData(state, action) {
      state.recordBooksData = action.payload;
    },

    storeSupportAndGrants(state, action) {
      state.supportAndGrantRecords = action.payload;
    },

    storeSanitationData(state, action) {
      state.sanitationData = action.payload;
    },

    storeSecurityData(state, action) {
      state.securityData = action.payload;
    },

    storeSportsData(state, action) {
      state.sportsData = action.payload;
    },

    storeStructureData(state, action) {
      state.structureData = action.payload;
    },

    storeFurnitureData(state, action) {
      state.furnitureData = action.payload;
    },

    storeWashData(state, action) {
      state.washData = action.payload;
    },

    storeCommunityData(state, action) {
      state.communityData = action.payload;
    },

    storeMeetingsData(state, action) {
      state.meetingsData = action.payload;
    },

    storeIssuesData(state, action) {
      state.issuesData = action.payload;
    },
    setSelectedDistrict(state, action) {
      state.selectedDistrict = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    }
  }
});

export const districtActions = districtSlice.actions;
export default districtSlice.reducer;
