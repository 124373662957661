import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts

import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// components

import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed"
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <NationalGuard>
              <DashboardPage />
            </NationalGuard>
          )
        },
        { path: "analytics", element: <AnalyticsPage /> },
        { path: "wash", element: <WashPage /> },
        { path: "help", element: <HelpPage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "about", element: <AboutPage /> },
        { path: "login", element: <LoginPage /> },
        {
          path: "submissions",
          children: [
            { element: <Navigate to="/dashboard/submissions/school" replace /> },
            {
              path: "schools",
              children: [
                { path: "", element: <SchoolsPage /> },
                { path: ":schoolId", element: <SchoolDetail /> }
              ]
            },
            {
              path: "circuits",
              children: [
                { path: "", element: <CircuitsPage /> },
                { path: ":circuitId", element: <CircuitDetail /> }
              ]
            },
            {
              path: "districts",
              children: [
                {
                  path: "",
                  element: (
                    <AuthLevelGuard allowedLevel={2}>
                      <DistrictsPage />
                    </AuthLevelGuard>
                  )
                },
                {
                  path: ":districtId",
                  element: (
                    <AuthLevelGuard allowedLevel={2}>
                      <DistrictDetail />
                    </AuthLevelGuard>
                  )
                }
              ]
            },
            {
              path: "regions",
              children: [
                {
                  path: "",
                  element: (
                    <AuthLevelGuard allowedLevel={3}>
                      <RegionsPage />
                    </AuthLevelGuard>
                  )
                },
                {
                  path: ":regionId",
                  element: (
                    <AuthLevelGuard allowedLevel={3}>
                      <RegionDetail />
                    </AuthLevelGuard>
                  )
                }
              ]
            },
            {
              path: "national",
              element: (
                <AuthLevelGuard allowedLevel={4}>
                  <NationalPage />
                </AuthLevelGuard>
              )
            }
          ]
        },
        {
          path: "users",
          children: [
            { element: <Navigate to="/dashboard/users/national" replace /> },
            { path: ":userId", element: <UserDetail /> },
            { path: "national", element: <AdminsPage type="national" /> },
            { path: "regional", element: <AdminsPage type="regional" /> },
            { path: "district", element: <AdminsPage type="district" /> },
            { path: "circuit", element: <AdminsPage type="circuit" /> },
            { path: "facilitators", element: <FacilitatorsPage /> },
            { path: "logs", element: <UserLogsPage /> }
          ]
        },
        {
          path: "archives",
          children: [
            { element: <Navigate to="/dashboard/archives/transfers" replace /> },
            { path: "deleted", element: <DeletedArchivesPage /> },
            { path: "transfers", element: <TransfersPage /> }
          ]
        },
        {
          path: "issues",
          children: [
            { element: <Navigate to="/dashboard/issues/resolved" replace /> },
            { path: "resolved", element: <ResolvedPage /> },
            { path: "app", element: <AppIssuesPage /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: "/",
      element: <Navigate to="/dashboard" replace />
      // children: [{ element: <LandingPage /> }]
    },
    { path: "/login", element: <LoginPage /> },
    { path: "/unauthorized", element: <UnauthorizedPage /> },
    { path: "*", element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard General (Single Pages)
const DashboardPage = Loadable(lazy(() => import("../pages/general/DashboardPage")));
const AboutPage = Loadable(lazy(() => import("../pages/general/AboutPage")));
const SettingsPage = Loadable(lazy(() => import("../pages/general/SettingsPage")));
const AnalyticsPage = Loadable(lazy(() => import("../pages/general/AnalyticsPage")));
const HelpPage = Loadable(lazy(() => import("../pages/general/HelpPage")));
const WashPage = Loadable(lazy(() => import("../pages/general/Wash")));

// Submissions
const SchoolsPage = Loadable(lazy(() => import("../pages/submissions/SchoolsPage")));
const SchoolDetail = Loadable(lazy(() => import("../components/submissions/schools/SchoolDetail")));

const CircuitsPage = Loadable(lazy(() => import("../pages/submissions/CircuitsPage")));
const CircuitDetail = Loadable(lazy(() => import("../components/submissions/circuits/CircuitDetail")));

const DistrictsPage = Loadable(lazy(() => import("../pages/submissions/DistrictsPage")));
const DistrictDetail = Loadable(lazy(() => import("../components/submissions/districts/DistrictDetail")));

const RegionsPage = Loadable(lazy(() => import("../pages/submissions/RegionsPage")));
const RegionDetail = Loadable(lazy(() => import("../components/submissions/regions/RegionDetail")));

const NationalPage = Loadable(lazy(() => import("../pages/submissions/NationalPage")));

// Users
const AdminsPage = Loadable(lazy(() => import("../pages/users/AdminsPage")));
const FacilitatorsPage = Loadable(lazy(() => import("../pages/users/FacilitatorsPage")));
const UserDetail = Loadable(lazy(() => import("../components/users/details/UserDetail")));
const UserLogsPage = Loadable(lazy(() => import("../pages/users/UserLogsPage")));

// Archive Pages
const DeletedArchivesPage = Loadable(lazy(() => import("../pages/archive/DeletedArchivesPage")));
const TransfersPage = Loadable(lazy(() => import("../pages/archive/TransfersPage")));

// Issues Pages
const AppIssuesPage = Loadable(lazy(() => import("../pages/issues/AppIssuesPage")));
const ResolvedPage = Loadable(lazy(() => import("../pages/issues/ResolvedPage")));

const NotFound = Loadable(lazy(() => import("../pages/Page404")));

// AUTH
const LoginPage = Loadable(lazy(() => import("../pages/auth/LoginPage")));
const UnauthorizedPage = Loadable(lazy(() => import("../pages/auth/UnauthorizedPage")));
const AuthGuard = Loadable(lazy(() => import("../guards/AuthGuard")));
const AuthLevelGuard = Loadable(lazy(() => import("../guards/AuthLevelGuard")));
const NationalGuard = Loadable(lazy(() => import("../guards/NationalGuard")));
