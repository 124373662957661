// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboardPage: path(ROOTS_DASHBOARD, "/"),
    analyticsPage: path(ROOTS_DASHBOARD, "/analytics"),
    washPage: path(ROOTS_DASHBOARD, "/wash"),
    helpPage: path(ROOTS_DASHBOARD, "/help"),
    settingsPage: path(ROOTS_DASHBOARD, "/settings"),
    aboutPage: path(ROOTS_DASHBOARD, "/about"),
    loginPage: path(ROOTS_DASHBOARD, "/login")
  },
  submissions: {
    root: path(ROOTS_DASHBOARD, "/submissions"),

    schoolsPage: path(ROOTS_DASHBOARD, "/submissions/schools"),
    schoolDetail: path(ROOTS_DASHBOARD, "submissions/schools/:schoolId"),

    circuitsPage: path(ROOTS_DASHBOARD, "/submissions/circuits"),
    circuitDetail: path(ROOTS_DASHBOARD, "submissions/circuits/:circuitId"),

    districtsPage: path(ROOTS_DASHBOARD, "/submissions/districts"),
    districtDetail: path(ROOTS_DASHBOARD, "submissions/districts/:districtId"),

    regionsPage: path(ROOTS_DASHBOARD, "/submissions/regions"),
    regionDetail: path(ROOTS_DASHBOARD, "submissions/regions/:regionId"),

    nationalPage: path(ROOTS_DASHBOARD, "/submissions/national")
  },
  users: {
    root: path(ROOTS_DASHBOARD, "/users"),
    userDetail: path(ROOTS_DASHBOARD, "/users/:userId"),
    nationalUsersPage: path(ROOTS_DASHBOARD, "/users/national"),
    regionalUsersPage: path(ROOTS_DASHBOARD, "/users/regional"),
    districtUsersPage: path(ROOTS_DASHBOARD, "/users/district"),
    circuitUsersPage: path(ROOTS_DASHBOARD, "/users/circuit"),
    facilitatorsPage: path(ROOTS_DASHBOARD, "/users/facilitators"),
    userLogsPage: path(ROOTS_DASHBOARD, "/users/logs")
  },
  archives: {
    root: path(ROOTS_DASHBOARD, "/archives"),
    deletedArchivesPage: path(ROOTS_DASHBOARD, "/archives/deleted"),
    transfersPage: path(ROOTS_DASHBOARD, "/archives/transfers")
  },
  issues: {
    root: path(ROOTS_DASHBOARD, "/issues"),
    appIssuesPage: path(ROOTS_DASHBOARD, "/issues/app"),
    resolvedPage: path(ROOTS_DASHBOARD, "/issues/resolved")
  }
};
