/* eslint-disable consistent-return */
import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Schools",
  postUrl: null,
  fetchedSchools: false,
  isFetching: false,
  selectedCircuit: 0,
  selectedSchool: 0,
  teachers: [],
  teacherStats: null,
  previewSchool: null,
  schoolSummary: null,
  enrolmentData: null,
  studentAttendance: null,
  teacherAttendance: null,
  textbookData: null,
  studentPerformance: null,
  recordBooksData: null,
  supportRecords: null,
  grantRecords: null,
  sanitationData: null,
  securityData: null,
  sportsData: null,
  structureData: null,
  furnitureData: null,
  washData: null,
  communityData: null,
  meetingsData: null,
  issuesData: null,
  selectedGrades: [],
  schoolAnalytics: null,
  selectTermFormValues: {
    year: "0",
    term: "0",
    semester: "0"
  },
  validSchools: [],
  schools: [],
  schoolsCache: {},
  schoolToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    name: "",
    description: "",
    isWashBeneficiary: 0,
    hasShift: 0,
    isGalop: 0,
    isSpecial: 0,
    EMISCode: "",
    grades: [],
    region: "",
    regionId: 0,
    district: "",
    districtId: 0,
    circuit: "",
    circuitId: 0
  }
};

const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
      state.title = "All Schools";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add A School";
      state.postUrl = `${API_URL}/school`;
      state.defaultValues = {
        name: "",
        description: "",
        isWashBeneficiary: 0,
        hasShift: 0,
        isGalop: 0,
        isSpecial: 0,
        EMISCode: "",
        grades: [],
        region: "",
        regionId: 0,
        district: "",
        districtId: 0,
        circuit: "",
        circuitId: 0
      };
    },
    edit(state, action) {
      const school = action.payload;

      state.mode = "edit";
      state.title = `Edit ${school.name}`;
      state.postUrl = `${API_URL}/school/${school.id}`;
      state.selectedGrades = school.grades;
      state.defaultValues = {
        name: school.name,
        description: school.description,
        grades: school.grades,
        isWashBeneficiary: !!school.isWashBeneficiary,
        EMISCode: school.ges_code === null ? "" : school.ges_code,
        hasShift: !!school.has_shift,
        isGalop: !!school.is_galop,
        isSpecial: !!school.is_special,
        regionId: school?.region_id || 0,
        districtId: school?.district_id || 0,
        circuitId: school?.circuit_id || 0
      };
    },
    detail(state, action) {
      // state.mode = "detail";

      state.previewSchool = action.payload;
      // state.schoolSummary = null;
    },
    delete(state, action) {
      state.mode = "delete";
      state.title = `Delete ${action.payload.name}`;
      state.schoolToDelete = {
        text: `Are you sure you want to delete ${action.payload.name}?`,
        id: action.payload.id
      };
    },
    storeSchools(state, action) {
      const { schools } = action.payload;
      state.schools = schools;
      state.isFetching = false;
    },
    storeTeachers(state, action) {
      state.teachers = action.payload;
    },
    saveTeacherStats(state, action) {
      state.teacherStats = action.payload;
    },
    setFetchedSchools(state, action) {
      state.fetchedSchools = action.payload;
    },
    setValidSchools(state, action) {
      const { schools, circuitId } = action.payload;

      if (!schools || circuitId === state.selectedCircuit) return;

      // Return all schools in the selected circuit
      state.validSchools = schools.filter((school) => school.circuit_id === circuitId);
      state.selectedCircuit = circuitId;

      // Set the selected School to "Select A School"
      state.selectedSchool = 0;
    },
    setSelectedSchool(state, action) {
      state.selectedSchool = action.payload;
    },
    setSelectedGrades(state, action) {
      state.selectedGrades = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    storeSelectTermFormValues(state, action) {
      state.selectTermFormValues = action.payload;
    },
    storeSchoolAnalytics(state, action) {
      state.schoolAnalytics = action.payload;
    },
    saveSchoolSummaries(state, action) {
      state.schoolSummary = action.payload;

      state.isFetching = false;
    },
    setReportCard(state, action) {
      let reportCard;
      // console.log(action.payload);
      if (action.payload.type === "enrolments") reportCard = action.payload.total_enrolment_data;
      if (action.payload.type === "student-attendance") reportCard = action.payload.total_attendance_data;

      const { total_population: total, special_needs: specNeeds, normal } = reportCard.totals;

      const stats = {
        total,
        totalBoys: normal.boys,
        totalSpecBoys: specNeeds.boys,
        totalGirls: normal.girls,
        totalSpecGirls: specNeeds.girls
      };

      const data = [];

      // class id = 1 or 2 --> KG
      // class id = 3, --> B1

      const entries = reportCard.items;

      entries.sort((a, b) => a.class_id - b.class_id);

      entries.forEach((entry, idx) => {
        const dataObj = {};
        dataObj.grade = entry.class_name;
        dataObj.boys = entry.normal.boys;
        dataObj.girls = entry.normal.girls;
        dataObj.total = +dataObj.boys + +dataObj.girls;
        dataObj.specBoys = entry.special_needs.boys;
        dataObj.specGirls = entry.special_needs.girls;
        dataObj.streams = entry.normal.stream;

        dataObj.id = idx;

        data.push(dataObj);

        // stats.total += +dataObj.boys + +dataObj.girls + +dataObj.specNeedBoys + +dataObj.specNeedGirls;
        // stats.totalSpecial += +dataObj.specNeedBoys + +dataObj.specNeedGirls;
      });

      if (action.payload.type === "enrolments") state.enrolmentData = { data, stats };
      if (action.payload.type === "student-attendance") state.studentAttendance = { data, stats };
    },

    setTeacherAttendance(state, action) {
      const entries = action.payload;
      // console.log(entries);
      // if (entries.length === 0) return (state.teacherAttendance = null);

      state.teacherAttendance = entries.map((entry, idx) => {
        const data = {
          id: idx,
          fullName: `${entry.teacher.first_name} ${entry.teacher.last_name}`,
          staffNumber: entry.teacher.staff_number,
          gender: entry.teacher.gender,

          // levelTaught: "N/A",
          // subject: "N/A",
          daysPresent: entry.days_present,
          daysPunctual: entry.days_punctual,
          daysAbsent: entry.days_absent,
          absentWithPerm: entry.days_absent_with_permission,
          absentWithoutPerm: entry.days_absent_without_permission,
          exercisesGiven: entry.excises_given,
          exercisesMarked: entry.excises_marked,

          lessonPlanRatings: entry.lesson_plan_ratings,

          rank: entry.teacher.rank,
          academicQualification: entry.teacher.academic_qualification,
          professionalQualification: entry.teacher.professional_qualification,
          email: entry.teacher.email,
          phoneNumber: entry.teacher.phone_number,
          status: entry.teacher.status,
          category: entry.teacher.category
        };
        return data;
      });
    },

    removeReportCard(state) {
      state.studentAttendance = null;
      state.enrolmentData = null;
      state.teacherAttendance = null;
    },

    storeTextbookData(state, action) {
      state.textbookData = action.payload;
    },

    storeStudentPerfData(state, action) {
      state.studentPerformance = action.payload;
    },

    storeRecordBooksData(state, action) {
      const data = action.payload.record_books_data_object;

      state.recordBooksData = {
        SBA: data.sba,
        logBook: data.log_book,
        visitorsLog: data.visitors_log,
        classRegister: data.class_register,
        inventoryBooks: data.inventory_books,
        movementRegister: data.movement_register,
        admissionRegister: data.admission_register,
        continuousAssessment: data.continuous_assessment,
        cumulativeRecordsBooks: data.cumulative_records_books,
        teacherAttendanceRegister: data.teacher_attendance_register,
        schoolPerformanceImprovementPlan: data.school_performance_improvement_plan
      };
    },

    storeSupportAndGrants(state, action) {
      state.supportRecords = action.payload.support_data_object;
      state.grantRecords = action.payload.grants_data_object;
    },

    storeSanitationData(state, action) {
      state.sanitationData = action.payload;
    },

    storeSecurityData(state, action) {
      state.securityData = action.payload;
    },

    storeSportsData(state, action) {
      state.sportsData = action.payload;
    },

    storeStructureData(state, action) {
      state.structureData = action.payload;
    },

    storeFurnitureData(state, action) {
      state.furnitureData = action.payload;
    },

    storeWashData(state, action) {
      state.washData = action.payload;
    },

    storeCommunityData(state, action) {
      state.communityData = action.payload;
    },

    storeMeetingsData(state, action) {
      state.meetingsData = action.payload;
    },

    storeIssuesData(state, action) {
      state.issuesData = action.payload;
    },

    clearSavedPreviewSchool(state) {
      // state.previewSchool = null;
      state.schoolSummary = null;
      state.enrolmentData = null;
      state.studentAttendance = null;
      state.teacherAttendance = null;
    }
  }
});

export const schoolActions = schoolSlice.actions;
export default schoolSlice.reducer;
