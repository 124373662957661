import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Facilitators",
  postUrl: null,
  fetchedFacilitators: false,
  isFetching: false,
  facilitators: [],
  facilitatorsCache: {},
  facilitatorStats: { total: 0, males: 0, females: 0 },
  facilitatorToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    type: "head_teacher",
    firstName: "",
    lastName: "",
    otherNames: "",
    staffNumber: "",
    email: "",
    phoneNumber: "",
    sex: "",
    regionId: 0,
    districtId: 0,
    circuitId: 0,
    school: "",
    schoolId: 0
  }
};

const facilitatorSlice = createSlice({
  name: "facilitator",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
      state.title = "All Facilitators";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add A Facilitator";
      state.postUrl = `${API_URL}/user/head_teacher`;
      state.defaultValues = {
        type: "head_teacher",
        firstName: "",
        lastName: "",
        otherNames: "",
        staffNumber: "",
        email: "",
        phoneNumber: "",
        sex: "",
        regionId: 0,
        districtId: 0,
        circuitId: 0,
        school: "",
        schoolId: 0
      };
    },
    edit(state, action) {
      // console.log(action.payload);
      const facilitator = action.payload;

      state.mode = "edit";
      state.title = `Edit ${facilitator.first_name} ${facilitator.other_names || ""} ${facilitator.last_name}`;
      state.postUrl = `${API_URL}/users/${facilitator.id}`;
      state.defaultValues = {
        type: "head_teacher",
        firstName: facilitator.first_name,
        lastName: facilitator.last_name,
        otherNames: facilitator.other_names || "",
        email: facilitator.email,
        sex: facilitator.gender,
        staffNumber: facilitator.identification_number || "",
        phoneNumber: facilitator.phone_number,
        regionId: facilitator.school?.region_id || 0,
        districtId: facilitator.school?.district_id || 0,
        circuitId: facilitator.school?.circuit_id || 0,
        school: facilitator.school || "N/A",
        schoolId: facilitator.school?.id || 0
      };
    },
    detail(state, action) {
      state.mode = "detail";
      state.title = action.payload.fullName;
    },
    delete(state, action) {
      state.mode = "delete";
      state.title = `Delete ${action.payload.fullName}`;
      state.facilitatorToDelete = {
        text: `Are you sure you want to delete ${action.payload.fullName}?`,
        id: action.payload.id
      };
    },
    storeFacilitators(state, action) {
      const { facilitators } = action.payload;
      state.facilitators = facilitators;
      state.isFetching = false;
    },
    setFetchedFacilitators(state, action) {
      state.fetchedFacilitators = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    }
  }
});

export const facilitatorActions = facilitatorSlice.actions;
export default facilitatorSlice.reducer;
