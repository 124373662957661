import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
// import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
// import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import SvgIconStyle from "../../components/SvgIconStyle";

const storageIcon = <StorageOutlinedIcon />;
const usersIcon = <PeopleAltOutlinedIcon />;
// const archiveIcon = <ListAltOutlinedIcon />;
// const helpIcon = <HelpCenterOutlinedIcon />;
// const bugIcon = <BugReportOutlinedIcon />;
// const settingsIcon = <SettingsOutlinedIcon />;
const aboutIcon = <InfoOutlinedIcon />;
const washIcon = <LocalHospitalIcon />;
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: "100%", height: "100%" }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard")
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      { title: "Dashboard", path: PATH_DASHBOARD.general.dashboardPage, icon: ICONS.dashboard },
      {
        title: "submissions",
        path: "",
        icon: storageIcon,
        children: [
          { title: "School", path: PATH_DASHBOARD.submissions.schoolsPage },
          { title: "Circuit", path: PATH_DASHBOARD.submissions.circuitsPage },
          { title: "District", path: PATH_DASHBOARD.submissions.districtsPage },
          { title: "Region", path: PATH_DASHBOARD.submissions.regionsPage }
          // { title: "National", path: PATH_DASHBOARD.submissions.nationalPage }
        ]
      },
      // { title: "Analytics", path: PATH_DASHBOARD.general.analyticsPage, icon: ICONS.analytics },
      {
        title: "users",
        path: "",
        icon: usersIcon,
        children: [
          { title: "National Admins", path: PATH_DASHBOARD.users.nationalUsersPage },
          { title: "Regional Admins", path: PATH_DASHBOARD.users.regionalUsersPage },
          { title: "District Admins", path: PATH_DASHBOARD.users.districtUsersPage },
          { title: "Circuit Supervisors", path: PATH_DASHBOARD.users.circuitUsersPage },
          { title: "Head Facilitators", path: PATH_DASHBOARD.users.facilitatorsPage },
          { title: "User Logs", path: PATH_DASHBOARD.users.userLogsPage }
        ]
      },
      { title: "WASH", path: PATH_DASHBOARD.general.washPage, icon: washIcon },

      // {
      //   title: "archive",
      //   path: "",
      //   icon: archiveIcon,
      //   children: [
      //     { title: "Transfers", path: PATH_DASHBOARD.archives.transfersPage },
      //     { title: "Deleted Archives", path: PATH_DASHBOARD.archives.deletedArchivesPage }
      //   ]
      // },
      // { title: "Help", path: PATH_DASHBOARD.general.helpPage, icon: helpIcon },
      // {
      //   title: "issues",
      //   path: "",
      //   icon: bugIcon,
      //   children: [
      //     { title: "Mobile App Issues", path: PATH_DASHBOARD.issues.appIssuesPage },
      //     { title: "Resolved Issues", path: PATH_DASHBOARD.issues.resolvedPage }
      //   ]
      // },
      // { title: "Settings", path: PATH_DASHBOARD.general.settingsPage, icon: settingsIcon },
      { title: "About", path: PATH_DASHBOARD.general.aboutPage, icon: aboutIcon }
    ]
  }
];

export default sidebarConfig;
