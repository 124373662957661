import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Circuits",
  postUrl: null,
  fetchedCircuits: false,
  isFetching: false,
  circuits: [],
  teachers: [],
  teacherStats: null,
  previewCircuit: null,
  circuitSummary: null,
  enrolmentData: null,
  totalEnrolment: 0,
  analyticsQuery: "",
  attendanceData: null,
  teacherAttendance: null,
  selectTermFormValues: {
    year: "0",
    term: "0",
    semester: "0"
  },
  textbookData: null,
  studentPerformance: null,
  recordBooksData: null,
  supportAndGrantRecords: null,
  sanitationData: null,
  securityData: null,
  sportsData: null,
  structureData: null,
  furnitureData: null,
  washData: null,
  communityData: null,
  meetingsData: null,
  issuesData: null,
  circuitsCache: {},
  selectedDistrict: 0,
  selectedCircuit: 0,
  validCircuits: [],
  circuitToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    name: "",
    description: "",
    region: "0",
    district: "0"
  }
};

const circuitSlice = createSlice({
  name: "circuit",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
      state.title = "All Circuits";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add A Circuit";
      state.postUrl = `${API_URL}/circuit`;
      state.defaultValues = {
        name: "",
        description: "",
        region: "",
        regionId: 0,
        district: "",
        districtId: 0
      };
    },
    edit(state, action) {
      state.mode = "edit";
      state.title = `Edit ${action.payload.name}`;
      state.postUrl = `${API_URL}/circuit/${action.payload.id}`;
      state.defaultValues = {
        name: action.payload.name,
        description: action.payload.description,
        region: action.payload.region,
        regionId: action.payload.region_id,
        district: action.payload.district,
        districtId: action.payload.district_id
      };
    },
    detail(state, action) {
      state.previewCircuit = action.payload;
    },
    delete(state, action) {
      state.mode = "delete";
      state.title = `Delete ${action.payload.name} Circuit`;
      state.circuitToDelete = {
        text: `Are you sure you want to delete the ${action.payload.name}?`,
        id: action.payload.id
      };
    },
    storeCircuits(state, action) {
      const { circuits, authLevel, user } = action.payload;

      // Handle national admin
      if (authLevel > 3.5) state.circuits = circuits;

      circuits.forEach((circuit) => {
        // Filter for regional admin
        if (authLevel > 2.5 && authLevel < 4) {
          if (circuit.region_id === user.region.id) {
            state.circuits.push(circuit);
          }

          // Filter for district admin
        } else if (authLevel > 1.5 && authLevel < 3) {
          if (circuit.district_id === user.district.id) {
            state.circuits.push(circuit);
          }

          // Filter for SISO
        } else if (authLevel > 0 && authLevel < 2) {
          if (circuit.id === user.circuit.id) state.circuits = [circuit];
        }

        // Handle CACHING
        if (state.circuitsCache[circuit.id]) return;

        state.circuitsCache[circuit.id] = circuit.name;
      });
      state.isFetching = false;
    },
    storeTeachers(state, action) {
      state.teachers = action.payload;
    },
    saveTeacherStats(state, action) {
      state.teacherStats = action.payload;
    },

    setFetchedCircuits(state, action) {
      state.fetchedCircuits = action.payload;
    },
    setValidCircuits(state, action) {
      const { circuits, districtId } = action.payload;
      if (!circuits) return;

      if (districtId === state.selectedDistrict) return;

      // Return all circuits in the selected district
      state.validCircuits = circuits.filter((circuit) => circuit.district_id === districtId);
      state.selectedDistrict = districtId;

      // Set the selected Circuit to "Select A Circuit"
      state.selectedCircuit = 0;
    },
    saveCircuitSummaries(state, action) {
      state.circuitSummary = action.payload;
    },
    saveEnrolmentData(state, action) {
      state.enrolmentData = action.payload;
    },
    saveAttendanceData(state, action) {
      state.attendanceData = action.payload;
    },
    saveTeacherAttendance(state, action) {
      state.teacherAttendance = action.payload;
    },
    setAnalyticsQuery(state, action) {
      state.analyticsQuery = action.payload;
    },
    saveTotalEnrolment(state, action) {
      state.totalEnrolment = action.payload;
    },
    storeSelectTermFormValues(state, action) {
      state.selectTermFormValues = action.payload;
    },
    storeTextbookData(state, action) {
      state.textbookData = action.payload;
    },

    storeStudentPerfData(state, action) {
      state.studentPerformance = action.payload;
    },

    storeRecordBooksData(state, action) {
      state.recordBooksData = action.payload;
    },

    storeSupportAndGrants(state, action) {
      state.supportAndGrantRecords = action.payload;
    },

    storeSanitationData(state, action) {
      state.sanitationData = action.payload;
    },

    storeSecurityData(state, action) {
      state.securityData = action.payload;
    },

    storeSportsData(state, action) {
      state.sportsData = action.payload;
    },

    storeStructureData(state, action) {
      state.structureData = action.payload;
    },

    storeFurnitureData(state, action) {
      state.furnitureData = action.payload;
    },

    storeWashData(state, action) {
      state.washData = action.payload;
    },

    storeCommunityData(state, action) {
      state.communityData = action.payload;
    },

    storeMeetingsData(state, action) {
      state.meetingsData = action.payload;
    },

    storeIssuesData(state, action) {
      state.issuesData = action.payload;
    },
    setSelectedCircuit(state, action) {
      state.selectedCircuit = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    }
  }
});

export const circuitActions = circuitSlice.actions;
export default circuitSlice.reducer;
