import PropTypes from "prop-types";
// material

import { Box } from "@mui/material";

import MSRCLogo from "../assets/msrc-logo.png";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 70, height: 70, ...sx }}>
      <img src={MSRCLogo} alt="MSRC Logo" />
    </Box>
  );
}
