/* eslint-disable consistent-return */
import axios from "../utils/axios";
import { API_URL } from "../config";

export const storeLoginData = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
  localStorage.setItem("authLevel", data.authLevel);
  localStorage.setItem("isSuperAdmin", data.isSuperAdmin);
};

export const removeLoginData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("authLevel");
  localStorage.removeItem("isSuperAdmin");
};

export const retrieveLoginData = () => {
  const savedToken = localStorage.getItem("token");
  const savedUser = JSON.parse(localStorage.getItem("user"));
  const savedAuthLevel = +localStorage.getItem("authLevel");
  const savedSuperAdmin = localStorage.getItem("isSuperAdmin");

  return {
    user: savedUser,
    token: savedToken,
    authLevel: savedAuthLevel,
    isSuperAdmin: savedSuperAdmin
  };
};

export const setUserAuthLevel = (user) => {
  const role = user.type.split("_")[0];
  let authLevel;

  switch (role) {
    case "national":
      authLevel = user.super_admin ? 4.5 : 4;
      break;

    case "regional":
      authLevel = user.super_admin ? 3.5 : 3;
      break;

    case "district":
      authLevel = user.super_admin ? 2.5 : 2;
      break;

    case "circuit":
      authLevel = 1;
      break;
    default:
      authLevel = 0;
      break;
  }

  return authLevel;
};

export const sendLoginRequest = async ({ values }) => {
  try {
    const res = await axios.post(`${API_URL}/login`, values);

    if (res.status === 200) return res.data.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getUser = async (data) => {
  try {
    const res = await axios.get(`${API_URL}/user/${data.id}`, data.header);

    if (res.status === 200) return res.data.data;
  } catch (err) {
    console.error(err);
  }
};
