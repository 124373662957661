import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Admins",
  isFetching: false,
  postUrl: null,
  admins: [],
  storedAdmins: {
    national: [],
    regional: [],
    district: [],
    circuit: []
  },
  fetchedAdmins: {
    national: false,
    regional: false,
    district: false,
    circuit: false
  },
  adminToEdit: {},
  selectedRole: null,
  adminsCache: {},
  adminToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    type: null,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: "0",
    sex: "0",
    isSuperAdmin: "0",
    regionId: 0,
    districtId: 0,
    circuitId: 0,
    schoolId: 0
  }
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add An Admin";
      state.defaultValues = {
        type: null,
        firstName: "",
        lastName: "",
        otherNames: "",
        email: "",
        phoneNumber: "",
        sex: "0",
        isSuperAdmin: "0",
        role: "0",
        regionId: 0,
        districtId: 0,
        circuitId: 0,
        school: "",
        schoolId: 0
      };
    },
    edit(state, action) {
      const admin = action.payload;
      const role = admin.type.split("_").at(0);
      // console.log(admin);
      state.mode = "edit";
      state.title = `Edit ${admin.first_name} ${admin.other_names || ""} ${admin.last_name}`;
      state.adminToEdit = admin;
      state.selectedRole = role;
      state.postUrl = `${API_URL}/users/${admin.id}`;

      let regionId = 0;
      let districtId = 0;
      let circuitId = 0;

      switch (role) {
        case "regional":
          regionId = admin.region?.id || 0;
          break;

        case "district":
          districtId = admin.district?.id || 0;
          regionId = admin.district?.region_id || 0;
          break;

        case "circuit":
          circuitId = admin.circuit?.id || 0;
          districtId = admin.circuit?.district_id || 0;
          regionId = admin.circuit?.region_id || 0;
          break;
        default:
          break;
      }

      // console.log(admin);
      state.defaultValues = {
        type: `${admin.type}`,
        firstName: admin.first_name,
        lastName: admin.last_name,
        otherNames: admin.other_names || "",
        email: admin.email,
        sex: admin.gender || "0",
        role: admin.type.split("_").at(0),
        scopeId: admin.scope_id,
        regionId,
        districtId,
        circuitId,
        isSuperAdmin: admin.super_admin,
        phoneNumber: admin.phone_number
      };
    },
    detail(state, action) {
      state.mode = "detail";
      state.title = action.payload.fullName;
    },
    delete(state, action) {
      state.mode = "delete";
      state.title = `Delete ${action.payload.fullName}`;
      state.adminToDelete = {
        text: `Are you sure you want to delete ${action.payload.fullName}?`,
        id: action.payload.id
      };
    },
    storeAdmins(state, action) {
      const { admins, type } = action.payload;
      // If user is a national admin, store all admins
      state.storedAdmins[`${type}`] = admins;

      state.isFetching = false;
    },

    setFetchedAdmins(state, action) {
      const { type, value } = action.payload;
      state.fetchedAdmins[type] = value;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
      const type = `${action.payload}_${action.payload === "circuit" ? "supervisor" : "admin"}`;

      state.postUrl = state.mode === "create" ? `${API_URL}/user/${type}` : `${API_URL}/users/${state.adminToEdit.id}`;
    }
  }
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
