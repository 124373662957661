import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import nationalReducer from "./submissions/national";
import regionReducer from "./submissions/regions";
import districtReducer from "./submissions/districts";
import circuitReducer from "./submissions/circuits";
import schoolReducer from "./submissions/schools";
import facilitatorReducer from "./users/facilitators";
import adminReducer from "./users/admins";

const store = configureStore({
  reducer: {
    auth: authReducer,
    national: nationalReducer,
    region: regionReducer,
    district: districtReducer,
    circuit: circuitReducer,
    school: schoolReducer,
    facilitator: facilitatorReducer,
    admin: adminReducer
  }
});

export default store;
