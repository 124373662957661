import { API_URL } from "../../config";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mode: "list",
  title: "All Regions",
  postUrl: null,
  fetchedRegions: false,
  isFetching: false,
  regions: [],
  teachers: [],
  teacherStats: null,
  regionsCache: {},
  selectedRegion: 0,
  previewRegion: null,
  regionSummary: null,
  enrolmentData: null,
  attendanceData: null,
  totalEnrolment: 0,
  analyticsQuery: "",
  regionToDelete: {
    id: null,
    text: ""
  },
  defaultValues: {
    name: "",
    description: ""
  }
};
const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    list(state) {
      state.mode = "list";
      state.title = "All Regions";
    },
    create(state) {
      state.mode = "create";
      state.title = "Add A Region";
      state.postUrl = `${API_URL}/region`;
      state.defaultValues = {
        name: "",
        description: "",
        moeCode: ""
      };
    },
    edit(state, action) {
      const region = action.payload;

      state.mode = "edit";
      state.title = `Edit ${region.name}`;
      state.postUrl = `${API_URL}/region/${region.id}`;
      state.defaultValues = {
        name: region.name,
        description: region.description,
        moeCode: region.moe_integration_system_id || ""
      };
    },
    detail(state, action) {
      state.previewRegion = action.payload;
    },
    delete(state, action) {
      state.mode = "delete";
      state.regionToDelete = {
        text: `Are you sure you want to delete the ${action.payload.name}?`,
        id: action.payload.id
      };
    },
    setRenderRegions(state, action) {
      state.renderRegions = action.payload;
    },
    storeRegions(state, action) {
      const { user, authLevel, regions } = action.payload;

      // If user is a national admin, store all regions
      if (authLevel > 3.5) state.regions = regions;

      // Create Cache
      regions.forEach((region) => {
        // Filter for the region of the regional admin
        if (authLevel > 2.5 && authLevel < 4) {
          if (region.id === user.region.id) state.regions = [region];

          // Filter region for the district admin
        } else if (authLevel > 1.5 && authLevel < 3) {
          if (region.id === user.district?.region_id) {
            state.regions = [region];
          }

          // Filter region for CS
        } else if (authLevel > 0.5 && authLevel < 2) {
          if (region.id === user.circuit?.region_id) {
            state.regions = [region];
          }
        }

        if (state.regionsCache[region.id]) return;

        state.regionsCache[region.id] = region.name;
      });
      state.isFetching = false;
    },
    storeTeachers(state, action) {
      state.teachers = action.payload;
    },
    saveTeacherStats(state, action) {
      state.teacherStats = action.payload;
    },
    saveRegionSummaries(state, action) {
      state.regionSummary = action.payload;
    },
    saveEnrolmentData(state, action) {
      state.enrolmentData = action.payload;
    },
    saveAttendanceData(state, action) {
      state.attendanceData = action.payload;
    },
    setAnalyticsQuery(state, action) {
      state.analyticsQuery = action.payload;
    },
    saveTotalEnrolment(state, action) {
      state.totalEnrolment = action.payload;
    },
    setFetchedRegions(state, action) {
      state.fetchedRegions = action.payload;
    },
    setSelectedRegion(state, action) {
      state.selectedRegion = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    }
  }
});

export const regionActions = regionSlice.actions;
export default regionSlice.reducer;
