const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  teachers: [],
  teacherStats: null
};

const nationalSlice = createSlice({
  name: "national",
  initialState,
  reducers: {
    storeTeachers(state, action) {
      state.teachers = action.payload;
    },
    saveTeacherStats(state, action) {
      state.teacherStats = action.payload;
    }
  }
});

export const nationalActions = nationalSlice.actions;
export default nationalSlice.reducer;
